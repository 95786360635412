import React from "react";
import mainContentImg from "../assets/image/4889558.jpg";

const AdminMainContent = () => {
  return (
    <div className="bg-white h-full w-full p-2 flex flex-col items-center  text-gray-700">
      <img
        src={mainContentImg}
        alt=""
        className="w-[40rem] h-auto object-contain"
      />
      <h2 className="font-bold text-2xl">SELAMAT DATANG DI ADMIN PAGE</h2>
      <h2 className="font-bold text-2xl">PT. RUBBER JAYA LAMPUNG</h2>
      <p className="text-blue-600 underline pt-4">
        <a href="https://rubberjayalampung.com" target="_blank">
          Klik untuk mengunjungi Landing Page Rubber Jaya Lampung
        </a>
      </p>
    </div>
  );
};

export default AdminMainContent;
