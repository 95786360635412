import React, { useState, useEffect } from "react";
import section2Img from "../Images/section2Img.jpeg";
import { MdDownload } from "react-icons/md";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

const Sustainability = () => {
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          "https://api.rubberjayalampung.com/sustainability"
        );

        const datas = response.data;
        setAllData(datas.payload);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  if (loading) {
    return (
      <section
        className="min-h-screen flex flex-col items-center justify-center bg-cover bg-center"
        style={{ backgroundImage: `url(${section2Img})` }}
      >
        <CircularProgress />
      </section>
    );
  }
  return (
    <div>
      <section
        className="min-h-screen flex flex-col justify-center items-center bg-cover bg-center"
        style={{
          backgroundImage: `url(${section2Img})`,
        }}
      >
        <div className="min-h-screen w-full bg-gray-800 bg-opacity-30 flex flex-col items-center justify-center px-10 md:py-24 py-36">
          <h1 className="text-white text-4xl font-bold md:w-full text-center mb-6">
            {t(`sustainability.section1Title`)}
          </h1>
          <div className="flex flex-wrap justify-center gap-6 w-full mt-2 rounded-lg">
            {allData.map((data, index) => {
              return (
                <div
                  key={index}
                  className="md:w-1/2 w-full  rounded-lg transform transition duration-200 hover:bg-gray-700 hover:scale-105 cursor-pointer"
                >
                  <a
                    href={data.urlFile}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="flex text-white justify-between items-center p-4 rounded-lg bg-gray-700 bg-opacity-70">
                      <h3 className="font-medium">{data.judul}</h3>
                      <div>
                        <MdDownload />{" "}
                      </div>
                    </div>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Sustainability;
