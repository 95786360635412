import React, { useEffect, useState } from "react";
import section2Img from "../Images/section2Img.jpeg";
import artikelImg from "../Images/artikelImg.JPG";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CreateIcon from "@mui/icons-material/Create";
import { useTranslation } from "react-i18next";

const ArtikelDetail = ({ artikelDetail }) => {
  const { id } = useParams();
  const [data, setData] = useState(artikelDetail || null);
  const [loading, setLoading] = useState(!artikelDetail);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    // Jika artikelDetail belum ada (berarti user masuk langsung melalui URL), lakukan fetch
    if (!artikelDetail) {
      const fetchData = async () => {
        setLoading(true);
        try {
          const response = await axios.get(
            `https://api.rubberjayalampung.com/artikel/${id}`
          );

          const datas = response.data;
          setData(datas.payload);
        } catch (error) {
          setError(error);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [id, artikelDetail]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Bulan dimulai dari 0
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatDescription = (text) => {
    if (!text) {
      return navigate(`/artikel`);
    }

    return text.split("\n").map((item, index) => (
      <p key={index} className="mb-4">
        {item}
      </p>
    ));
  };

  if (loading) {
    return (
      <section
        className="min-h-screen flex items-center justify-center bg-cover bg-center"
        style={{ backgroundImage: `url(${artikelImg})` }}
      >
        <CircularProgress />
      </section>
    );
  }
  if (error)
    return (
      <div className="flex items-center justify-center min-h-screen">
        <p>Error: {error.message}</p>
      </div>
    );
  if (!data)
    return (
      <div className="flex items-center justify-center min-h-screen">
        <p>Data tidak tersedia. Silakan kembali ke halaman artikel.</p>
      </div>
    );

  return (
    <div>
      <section
        className="min-h-screen flex flex-col justify-center items-center bg-cover bg-center "
        style={{
          backgroundImage: `url(${artikelImg})`,
        }}
      >
        <div className="min-h-screen w-full flex flex-col items-center justify-center bg-gray-800 bg-opacity-80 px-4 md:px-10 py-32 md:py-24">
          <div className="flex flex-col gap-4 bg-white border-2 border-gray-500 bg-opacity-90  px-6 py-3 rounded-lg shadow-lg w-full max-w-4xl">
            <div className="text-gray-500 text-sm flex gap-2">
              <Link to="/artikel">
                <div className="flex gap-2">
                  <p>{"<"}</p>
                  <p className="font-semibold hover:underline">
                    {t(`articleDetail.back`)}
                  </p>
                </div>
              </Link>
            </div>
            <img
              src={data.urlGambar === "" ? section2Img : data.urlGambar}
              alt=""
              className="w-full h-96 object-cover rounded-lg"
            />
            <div className="flex flex-col gap-2 text-sm text-gray-500 border-b border-b-gray-500 pb-4">
              <p>
                <CalendarMonthIcon fontSize="small" />{" "}
                {formatDate(data.created_at)}
              </p>
              <p>
                <CreateIcon fontSize="small" />
                {t(`articleDetail.writtenBy`)}{" "}
                <span className="font-semibold"> {data.penulis}</span>{" "}
              </p>
            </div>
            <h1 className="md:text-3xl text-2xl text-gray-800 font-bold pb-2 ">
              {data.judul}
            </h1>
            <p className=" text-gray-700 text-sm">
              {formatDescription(data.deskripsi)}
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ArtikelDetail;
