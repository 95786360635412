import React, { useState, useEffect } from "react";
import section2Img from "../Images/section2Img.jpeg";
import sertifikasiImg from "../Images/sertifikasiImg.JPG";
import axios from "axios";
import { CircularProgress, Pagination } from "@mui/material";
import { useTranslation } from "react-i18next";

const Sertifikasi = () => {
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(3);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          "https://api.rubberjayalampung.com/sertifikasi"
        );

        const datas = response.data;
        setAllData(datas.payload);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // Pagination logic
  const indexOfLastArticle = currentPage * itemsPerPage;
  const indexOfFirstArticle = indexOfLastArticle - itemsPerPage;
  const currentArticles = allData.slice(
    indexOfFirstArticle,
    indexOfLastArticle
  );

  const totalPages = Math.ceil(allData.length / itemsPerPage);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll ke bagian atas
  };

  if (loading) {
    return (
      <section
        className="min-h-screen flex flex-col items-center justify-center bg-cover bg-center"
        style={{ backgroundImage: `url(${sertifikasiImg})` }}
      >
        <CircularProgress />
      </section>
    );
  }

  return (
    <div>
      <section
        className="min-h-screen flex flex-col justify-center items-center bg-cover bg-center"
        style={{
          backgroundImage: `url(${sertifikasiImg})`,
        }}
      >
        <div className="min-h-screen w-full bg-gray-800 bg-opacity-60 flex flex-col items-center justify-end px-10 md:py-24 py-36">
          <h1 className="text-white text-4xl font-bold md:w-full text-center mb-6">
            {t(`certification.section1Title`)}
          </h1>
          <div className="flex flex-wrap justify-center gap-6 w-full mt-2 rounded-lg">
            {currentArticles.map((data, index) => {
              return (
                <div
                  key={index}
                  className="h-auto w-64  rounded-lg cursor-pointer"
                >
                  <a
                    href={data.urlFile}
                    download
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <img
                      src={data.urlGambar}
                      alt=""
                      className="object-contain bg-white rounded-t-lg w-full h-36 py-2 "
                    />
                    <div className=" p-2 rounded-b-lg bg-gray-600">
                      <p className="text-sm font-semibold text-center text-white">
                        {data.judul}
                      </p>
                    </div>
                  </a>
                </div>
              );
            })}
          </div>
          <div className="mt-8 bg-gray-600 bg-opacity-90 rounded-lg px-4 py-2">
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              siblingCount={1} // Mengatur jumlah angka yang ditampilkan di sebelah kiri dan kanan nomor halaman saat ini
              boundaryCount={1}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Sertifikasi;
