import React from "react";
import TableKarir from "../table/TableKarir";

const AdminKarir = () => {
  return (
    <div className="bg-gray-100 min-h-full w-full px-6 pt-6 flex flex-col justify-center">
      <h1 className="font-bold text-3xl">KARIR</h1>

      <div className=" w-full flex-1 py-4">
        <TableKarir />
      </div>
    </div>
  );
};

export default AdminKarir;
