import React, { useState } from "react";
import TableArtikel from "../table/TableArtikel";

const AdminArtikel = () => {
  return (
    <div className="bg-gray-100 min-h-full w-full px-6 pt-6 flex flex-col justify-center">
      <h1 className="font-bold text-3xl">ARTIKEL</h1>

      <div className=" w-full flex-1 py-4">
        <TableArtikel />
      </div>
    </div>
  );
};

export default AdminArtikel;
