import React from "react";
import depanRJL from "../Images/depanRJL.jpg";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import CallRoundedIcon from "@mui/icons-material/CallRounded";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import logoOlam from "../Images/logo_olam-removebg-preview.png";
import { useTranslation } from "react-i18next";

const HubungiKami = () => {
  const { t } = useTranslation();

  return (
    <div>
      <section
        className="h-screen  bg-cover bg-center"
        style={{
          backgroundImage: `url(${depanRJL})`,
        }}
      >
        <div className="h-full bg-black bg-opacity-70 flex items-center  justify-center md:px-10 px-2 ">
          <div>
            <h1 className="text-white text-4xl font-bold pb-2 mb-3 border-b-2 border-b-white ">
              {t(`contactUs.section1Title`)}
            </h1>
            <div className="flex text-sm md:text-base flex-col gap-4">
              <p className="flex gap-2 text-white items-center  ">
                <EmailRoundedIcon /> rubberjaya@gmail.com
              </p>
              <p className="flex gap-2 text-white items-center ">
                <CallRoundedIcon /> 0721 - 3580810
              </p>
              <div className="flex gap-2 items-center">
                <img src={logoOlam} alt="" className="h-4 w-auto" />
                <p className="flex gap-2 text-white items-center">
                  {t(`contactUs.collaboration`)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HubungiKami;
