import React, { useState, useEffect } from "react";
import axios from "axios";
import section2Img from "../Images/section2Img.jpeg";
import artikelImg from "../Images/artikelImg.JPG";
import { CircularProgress, Pagination } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa6";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CreateIcon from "@mui/icons-material/Create";
import { useTranslation } from "react-i18next";

const Artikel = ({ setSelectedArtikel }) => {
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://api.rubberjayalampung.com/artikel`
        );

        const datas = response.data;

        setAllData(datas.payload);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Bulan dimulai dari 0
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  const handleClick = (data) => {
    setSelectedArtikel(data);
    navigate(`/artikel/${data.id}`);
  };

  // Pagination logic
  const indexOfLastArticle = currentPage * itemsPerPage;
  const indexOfFirstArticle = indexOfLastArticle - itemsPerPage;
  const currentArticles = allData.slice(
    indexOfFirstArticle,
    indexOfLastArticle
  );

  const totalPages = Math.ceil(allData.length / itemsPerPage);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll ke bagian atas
  };

  if (loading) {
    return (
      <section
        className="min-h-screen flex flex-col items-center justify-center bg-cover bg-center"
        style={{ backgroundImage: `url(${artikelImg})` }}
      >
        <CircularProgress />
      </section>
    );
  }

  return (
    <div>
      <section
        className="min-h-screen flex flex-col justify-center items-center bg-contain bg-center "
        style={{ backgroundImage: `url(${artikelImg})` }}
      >
        <div className="min-h-screen w-full bg-gray-800 bg-opacity-80 flex flex-col items-center justify-center px-10 md:py-24 py-28">
          <h1 className="text-white text-4xl font-bold md:w-full text-center mb-6">
            {t(`articles.section1Title`)}
          </h1>

          <div className="flex flex-wrap justify-center md:gap-8 gap-6 w-full mt-2 rounded-lg ">
            {currentArticles.map((data) => (
              <div
                key={data.id}
                className="w-80 shadow-lg bg-white rounded-lg flex flex-col "
              >
                <img
                  src={data.urlGambar === "" ? section2Img : data.urlGambar}
                  alt=""
                  className="object-cover rounded-t-lg w-full md:h-48 h-32"
                />
                <div className="bg-white rounded-b-lg flex flex-col flex-grow">
                  <div className="px-4 py-3 flex flex-col text-gray-500 gap-2 border-b justify-center border-gray-300">
                    <p className="text-xs font-light ">
                      <CalendarMonthIcon fontSize="small" />{" "}
                      {formatDate(data.created_at)}
                    </p>
                    <p className="text-xs font-light ">
                      <CreateIcon fontSize="small" /> {t(`articles.writtenBy`)}{" "}
                      <span className="font-semibold">
                        {truncateText(data.penulis)}
                      </span>
                    </p>
                  </div>
                  <div className="px-4 py-2 flex flex-col gap-2 flex-grow">
                    <h2 className="md:text-lg text-sm font-semibold text-gray-700">
                      {truncateText(data.judul, 50)}
                    </h2>
                  </div>
                  <div className=" py-2 px-4 mt-auto ">
                    <button
                      onClick={() => handleClick(data)}
                      className="w-full text-sm py-2 px-4 rounded-md bg-red-600 hover:bg-red-800 text-white flex items-center justify-center gap-2"
                    >
                      Baca Selengkapnya <FaArrowRight />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* MUI Pagination Component */}
          <div className="mt-6 bg-white bg-opacity-90 rounded-lg px-4 py-2">
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              siblingCount={1} // Mengatur jumlah angka yang ditampilkan di sebelah kiri dan kanan nomor halaman saat ini
              boundaryCount={1}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Artikel;
