import React, { useState, useEffect } from "react";
import axios from "axios";
import section2Img from "../Images/section2Img.jpeg";
import karirImg2 from "../Images/karirImg2.jpg";
import { CircularProgress, Pagination } from "@mui/material";
import { useTranslation } from "react-i18next";

const Karir = () => {
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedImage, setSelectedImage] = useState(null);
  const { t } = useTranslation();

  const [itemsPerPage] = useState(6);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get("https://api.rubberjayalampung.com/karir");

        const datas = response.data;
        setAllData(datas.payload);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Bulan dimulai dari 0
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  // Pagination logic
  const indexOfLastArticle = currentPage * itemsPerPage;
  const indexOfFirstArticle = indexOfLastArticle - itemsPerPage;
  const currentArticles = allData.slice(
    indexOfFirstArticle,
    indexOfLastArticle
  );

  const totalPages = Math.ceil(allData.length / itemsPerPage);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll ke bagian atas
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  if (loading) {
    return (
      <section
        className="min-h-screen flex flex-col items-center justify-center bg-cover bg-center"
        style={{ backgroundImage: `url(${karirImg2})` }}
      >
        <CircularProgress />
      </section>
    );
  }

  return (
    <div>
      <section
        className="min-h-screen flex flex-col justify-center items-center bg-contain bg-center "
        style={{ backgroundImage: `url(${karirImg2})` }}
      >
        <div className="min-h-screen w-full bg-gray-800 bg-opacity-80 flex flex-col items-center justify-center px-10 md:py-24 py-28">
          <h1 className="text-white text-4xl font-bold md:w-full text-center mb-6">
            {t(`career.section1Title`)}
          </h1>

          <div className="flex flex-wrap justify-center md:gap-8 gap-6 w-full mt-2 rounded-lg ">
            {currentArticles.map((data) => (
              <div
                onClick={() =>
                  handleImageClick(
                    data.urlFile === "" ? section2Img : data.urlFile
                  )
                }
                key={data.id}
                className="w-72 shadow-lg rounded-lg cursor-pointer flex flex-col"
              >
                <img
                  src={data.urlFile === "" ? section2Img : data.urlFile}
                  alt=""
                  className="object-contain bg-white  rounded-t-lg w-full h-60"
                />
                <div className=" rounded-b-lg  flex flex-col flex-grow">
                  <div className="px-4 py-2 rounded-b-lg flex flex-col gap-2 flex-grow  bg-gray-600">
                    <h2 className="text-lg font-semibold text-white">
                      {truncateText(data.judul, 50)}
                    </h2>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-6 bg-white bg-opacity-90 rounded-lg px-4 py-2">
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              siblingCount={1} // Mengatur jumlah angka yang ditampilkan di sebelah kiri dan kanan nomor halaman saat ini
              boundaryCount={1}
            />
          </div>
          {selectedImage && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="relative bg-white rounded-lg py-4">
                <button
                  className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
                  onClick={closeModal}
                >
                  ✖
                </button>
                <img
                  src={selectedImage}
                  alt="Selected"
                  className="max-w-full max-h-[80vh] rounded-b-lg mt-4"
                />
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default Karir;
