import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "../Bahasa/en.json";
import translationID from "../Bahasa/id.json";

const resources = {
  en: { translation: translationEN },
  id: { translation: translationID },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "id", // Default bahasa
  fallbackLng: "en",
  interpolation: { escapeValue: false },
});

export default i18n;
