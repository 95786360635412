import React, { useState, useEffect } from "react";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import SnackbarFunction from "../function/SnackbarFunction";
import CircularProgress from "@mui/material/CircularProgress";

const TableSustainability = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");
  const [newData, setNewData] = useState({
    judul: "",
    urlFile: "",
    filePublicId: "",
  });

  const [editErrors, setEditErrors] = useState({});
  const [addErrors, setAddErrors] = useState({});

  const columns = [
    { id: "id", label: "No", minWidth: 50 },
    { id: "judul", label: "Judul", minWidth: 200 },
    { id: "aksi", label: "Aksi", minWidth: 150 },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.rubberjayalampung.com/sustainability"
        );

        const data = response.data;

        setAllData(data.payload);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const validateForm = (data) => {
    const errors = {};
    if (!data.judul) errors.judul = "Judul harus diisi";
    return errors;
  };

  const handleEdit = (row) => {
    setEditData(row);
    setEditErrors({});
    setEditDialogOpen(true);
  };
  const handleView = (row) => {
    setEditData(row);
    setViewDialogOpen(true);
  };

  const handleSaveEdit = async () => {
    const errors = validateForm(editData);
    if (Object.keys(errors).length > 0) {
      setEditErrors(errors);
      return;
    }

    try {
      await axios.put(
        `https://api.rubberjayalampung.com/put-sustainability/${editData.id}`,
        editData
      );
      // Re-fetch data after update
      const response = await axios.get(
        "https://api.rubberjayalampung.com/sustainability"
      );
      const data = response.data;
      setAllData(data.payload);
      setMessage("EDIT DATA");
      setSnackbarOpen(true);
      handleCloseEditDialog();
    } catch (error) {
      setError(error);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file); // Simpan file gambar ke state
    }
  };

  const handleSaveAdd = async () => {
    const errors = validateForm(newData);
    if (Object.keys(errors).length > 0) {
      setAddErrors(errors);
      return;
    }

    if (file.size > 2 * 1024 * 1024) {
      // 2MB dalam byte
      alert("Ukuran file tidak boleh lebih dari 2MB.");
      return; // Hentikan proses jika file terlalu besar
    }

    const validTypes = ["application/pdf"];
    if (!validTypes.includes(file.type)) {
      alert("Format file harus PDF.");
      return; // Hentikan proses jika format file tidak sesuai
    }

    try {
      // Tambahkan artikel terlebih dahulu
      const addResponse = await axios.post(
        "https://api.rubberjayalampung.com/post-sustainability",
        newData
      );

      const datas = addResponse.data;
      setLoading(true); // Set loading state to true
      setAddDialogOpen(false);

      // Jika ada file gambar, upload gambar ke Cloudinary
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", "rjl-artikel"); // Ganti dengan upload preset Cloudinary Anda
        formData.append("folder", "sustainability");
        const uploadResponse = await axios.post(
          "https://api.cloudinary.com/v1_1/dqvgmsrls/auto/upload",
          formData
        );
        const uploadedFileUrl = uploadResponse.data.secure_url; // URL gambar yang berhasil di-upload
        const publicId = uploadResponse.data.public_id; // Ambil public_id gambar

        // Update newData state dengan URL gambar dan public_id
        setNewData((prev) => {
          return {
            ...prev,
            urlFile: uploadedFileUrl,
            filePublicId: publicId,
          };
        });

        // Tunggu hingga state diperbarui (atau gunakan nilai baru langsung)
        await new Promise((resolve) => setTimeout(resolve, 100));

        // Gabungkan data yang ada dengan data baru untuk pembaruan
        const updatedData = {
          ...newData,
          urlFile: uploadedFileUrl,
          filePublicId: publicId,
        };

        // Update artikel dengan URL gambar dan public_id
        await axios.put(
          `https://api.rubberjayalampung.com/put-sustainability/${datas.payload.id}`,
          updatedData
        );
      } else {
        // Jika tidak ada gambar, kirimkan data yang ada
        await axios.put(
          `https://api.rubberjayalampung.com/put-sustainability/${datas.payload.id}`,
          newData
        );
      }

      // Re-fetch data after adding
      const response = await axios.get(
        "https://api.rubberjayalampung.com/sustainability"
      );
      const data = response.data;

      setAllData(data.payload);
      setMessage("TAMBAH DATA");
      setFile(null);
      setSnackbarOpen(true);
      handleCloseAddDialog();
    } catch (error) {
      if (error.response && error.response.status === 400) {
        alert("Kesalahan MIME");
        return alert(
          "Saran: upload file dengan format aslinya/dilarang merubah format file."
        );
      } else {
        // Jika error lain, simpan ke state
        setError(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (idToDelete, filePublicId) => {
    setLoading(true);
    try {
      // Hapus artikel dari server
      const response = await axios.delete(
        `https://api.rubberjayalampung.com/delete-sustainability/${idToDelete}`,
        {
          data: { filePublicId }, // Mengirim imagePublicId di body request
        }
      );

      // Pastikan penghapusan berhasil
      if (response.status === 200) {
        // Ambil ulang daftar artikel
        const sustainabilityResponse = await fetch(
          "https://api.rubberjayalampung.com/sustainability"
        );
        const sustainabilityData = await sustainabilityResponse.json();
        // Perbarui state atau UI
        setAllData(sustainabilityData.payload);
        setMessage("HAPUS DATA");
        setSnackbarOpen(true);
      } else {
        throw new Error("Failed to delete sertifikasi");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        alert("Kesalahan MIME");
        return alert(
          "Saran: upload file dengan format aslinya/dilarang merubah format file."
        );
      }
    } finally {
      setLoading(false);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditData((prev) => ({ ...prev, [name]: value }));
  };

  const handleNewDataChange = (event) => {
    const { name, value } = event.target;
    setNewData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
    setEditData(null);
  };
  const handleCloseViewDialog = () => {
    setViewDialogOpen(false);
    setEditData(null);
  };

  const handleCloseAddDialog = () => {
    setAddDialogOpen(false);
    setNewData({
      judul: "",
      urlFile: "",
      filePublicId: "",
    });
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <div
        style={{ display: "flex", justifyContent: "flex-end", padding: "16px" }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => setAddDialogOpen(true)}
        >
          <AddRoundedIcon />
          Tambah Sustainability
        </Button>
      </div>
      <TableContainer sx={{ maxHeight: 600 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align="center"
                  sx={{ minWidth: column.minWidth, fontFamily: `Poppins` }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {allData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  {columns.map((column) => {
                    const value =
                      column.id === "id"
                        ? page * rowsPerPage + index + 1
                        : row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align="center"
                        sx={{ fontFamily: "Poppins" }}
                        onClick={() => handleView(row)}
                        className="cursor-pointer"
                      >
                        {column.id === "judul" ? (
                          truncateText(row[column.id], 100)
                        ) : column.id === "aksi" ? (
                          <div>
                            <IconButton
                              color="secondary"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDelete(row.id, row.filePublicId);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        ) : (
                          value
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5]}
        component="div"
        count={allData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* Dialog Edit */}
      <Dialog
        open={editDialogOpen}
        onClose={handleCloseEditDialog}
        sx={{
          "& .MuiFormHelperText-root": {
            fontFamily: "Poppins",
          },
        }}
      >
        <DialogTitle>Edit Sustainability</DialogTitle>
        <DialogContent>
          {editData && (
            <div>
              <TextField
                autoFocus
                margin="dense"
                name="judul"
                label="Judul"
                type="text"
                multiline
                rows={2}
                fullWidth
                value={editData.judul}
                onChange={handleInputChange}
                error={!!editErrors.judul}
                helperText={editErrors.judul}
              />

              <input
                type="file"
                onChange={handleFileChange}
                className={`mt-4 `}
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog} disabled={loading}>
            Batal
          </Button>
          <Button onClick={handleSaveEdit} disabled={loading}>
            Simpan
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog Add */}
      <Dialog
        open={addDialogOpen}
        onClose={handleCloseAddDialog}
        sx={{
          "& .MuiFormHelperText-root": {
            fontFamily: "Poppins",
          },
        }}
      >
        <DialogTitle>Tambah Sustainability</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="judul"
            label="Judul"
            type="text"
            multiline
            rows={2}
            fullWidth
            value={newData.judul}
            onChange={handleNewDataChange}
            error={!!addErrors.judul}
            helperText={addErrors.judul}
          />

          <div className="mt-2 flex-col gap-2 p-2 rounded-md border border-gray-300 text-gray-600 font-light text-sm hover:border-black">
            <h2>Masukkan File</h2>
            <input type="file" onChange={handleFileChange} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddDialog} disabled={loading}>
            Batal
          </Button>
          <Button onClick={handleSaveAdd} disabled={loading}>
            Simpan
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog View */}
      <Dialog
        open={viewDialogOpen}
        onClose={handleCloseViewDialog}
        sx={{
          "& .MuiFormHelperText-root": {
            fontFamily: "Poppins",
          },
        }}
      >
        <DialogTitle>Lihat Sustainability</DialogTitle>
        <DialogContent>
          {editData && (
            <div>
              <TextField
                autoFocus
                margin="dense"
                name="judul"
                label="Judul"
                type="text"
                multiline
                rows={2}
                fullWidth
                value={editData.judul}
                disabled
              />

              <TextField
                margin="dense"
                name="urlFile"
                label="URL File"
                type="text"
                multiline
                rows={2}
                fullWidth
                value={editData.urlFile}
                disabled
              />
            </div>
          )}
        </DialogContent>
      </Dialog>

      <SnackbarFunction
        snackbarOpen={snackbarOpen}
        setSnackbarOpen={setSnackbarOpen}
        message={message}
      />

      {loading && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
          <CircularProgress />
        </div>
      )}
    </Paper>
  );
};

export default TableSustainability;
