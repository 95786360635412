import React from "react";
import logoISO14001 from "../Images/New folder/Logo ISO 14001.png";
import logoISO9001 from "../Images/New folder/LOGO 9001.png";
import logoRJL from "../Images/logoRJL.png";
import { IoLogoLinkedin } from "react-icons/io5";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className=" bg-gray-700 pt-20 px-10 pb-10 w-screen md:w-full text-white ">
      <div className="flex md:flex-row flex-col items-center">
        <div className="md:w-auto w-full">
          <h3 className="font-semibold text-3xl pb-2">{t(`footer.title`)}</h3>
          <p className="leading-7">
            {t(`footer.address1`)}
            <br></br>
            {t(`footer.address2`)} <br></br>
            {t(`footer.address3`)}
            <br></br>
            {t(`footer.address4`)}
          </p>
        </div>
      </div>
      <div className="mt-8 md:w-auto w-full  ">
        <h3 className="font-semibold text-3xl pb-2">{t(`footer.nav`)}</h3>
        <div>
          <div className="hover:text-red-600 w-fit pb-2">
            <Link to="/beranda">{t(`footer.navHome`)}</Link>
          </div>
          <div className="hover:text-red-600 w-fit pb-2">
            <Link to="/tentangkami">{t(`footer.navAboutUs`)}</Link>
          </div>
          <div className="hover:text-red-600 w-fit pb-2">
            <Link to="/bisnis">{t(`footer.navBusiness`)}</Link>
          </div>
          <div className="hover:text-red-600 w-fit pb-2">
            <Link to="/sertifikasi">{t(`footer.navCertification`)}</Link>
          </div>
          <div className="hover:text-red-600 w-fit pb-2">
            <Link to="/sustainability">{t(`footer.navSustainability`)}</Link>
          </div>
          <div className="hover:text-red-600 w-fit pb-2 ">
            <Link to="/artikel">{t(`footer.navArticles`)}</Link>
          </div>
          <div className="hover:text-red-600 w-fit pb-2 ">
            <Link to="/karir">{t(`footer.navCareer`)}</Link>
          </div>
          <div className="hover:text-red-600 w-fit pb-2">
            <Link to="/hubungikami">{t(`footer.navContactUs`)}</Link>
          </div>
        </div>
      </div>
      <div className="flex mt-8">
        <Link
          to="https://www.linkedin.com/company/rubber-jaya-lampung"
          target="_blank"
        >
          <IoLogoLinkedin className="cursor-pointer md:text-2xl text-xl" />
        </Link>
      </div>

      <div className="flex md:justify-center justify-center gap-5 basis-1/3 pt-6 w-full">
        <img src={logoRJL} className="md:w-24 md:h-24 w-16 h-16" />
        <img src={logoISO14001} className="md:w-24 md:h-24 w-16 h-16" />
        <img src={logoISO9001} className="md:w-24 md:h-24 w-16 h-16" />
      </div>

      <div className="py-2">
        <hr className="h-px border-none bg-white" />
      </div>

      <div className="py-2 ">
        <div>
          <p className="flex flex-nowrap text-white font-light">
            &copy; PT. Rubber Jaya Lampung. All Rights Reserved
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
