import React, { useState, useEffect } from "react";
import bisnisImg1 from "../Images/bisnisImg1.jpg";
import bisnisImg2 from "../Images/bisnisImg2.jpg";
import { FaArrowRight } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Bisnis = () => {
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          "https://api.rubberjayalampung.com/bisnis"
        );
        const datas = response.data;

        setAllData(datas.payload);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <section
        className="h-screen  bg-cover bg-center"
        style={{
          backgroundImage: `url(${bisnisImg1})`,
        }}
      >
        <div className="h-full bg-black bg-opacity-40 flex items-center  px-10 ">
          <div>
            <h1 className="text-white md:text-4xl  text-2xl  font-bold md:w-1/2">
              {t(`business.section1Title`)}
            </h1>
            <p className="text-white md:w-1/2 pt-6 pb-6 md:text-xl text-base border-b-2 border-white">
              {t(`business.section1Desc1`)}
            </p>
            <p className="text-white md:w-1/2 pt-6 pb-6 md:text-xl text-base ">
              {t(`business.section1Desc2`)}
            </p>
          </div>
        </div>
      </section>
      <section
        className="h-screen  bg-cover bg-center "
        style={{
          backgroundImage: `url(${bisnisImg2})`,
        }}
      >
        <div className="h-full bg-black bg-opacity-40 flex items-center px-10 ">
          <div>
            <h1 className="text-white md:text-4xl  text-2xl font-bold md:w-1/2">
              {t(`business.section2Title`)}
            </h1>
            <p className="text-white md:w-1/2 pt-6 pb-6 md:text-xl text-base">
              {t(`business.section2Desc1`)}
            </p>
            {allData.map((data, index) => (
              <a
                href={data.urlGambar}
                download="Proses-Bisnis.png"
                rel="noopener noreferrer"
              >
                <button className="py-2 px-4 rounded-md bg-red-600 text-white flex gap-2 items-center">
                  {t(`business.button`)}
                  <FaArrowRight />
                </button>
              </a>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Bisnis;
