import React, { useState, useEffect } from "react";
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
import Cookies from "js-cookie";
import Beranda from "./Components/Pages/Beranda";
import Artikel from "./Components/Pages/Artikel";
import Bisnis from "./Components/Pages/Bisnis";
import HubungiKami from "./Components/Pages/HubungiKami";
import Sertifikasi from "./Components/Pages/Sertifikasi";
import Sustainability from "./Components/Pages/Sustainability";
import TentangKami from "./Components/Pages/TentangKami";
import Footer from "./Components/Footer/Footer";
import Navbar from "./Components/Navbar/Navbar";
import ScrollOnTop from "./Components/Function/ScrollOnTop";
import ArtikelDetail from "./Components/Pages/ArtikelDetail";
import Karir from "./Components/Pages/Karir";
import AdminArtikel from "./Components/Admin/components/AdminArtikel";
import AdminMainContent from "./Components/Admin/components/AdminMainContent";
import AdminKarir from "./Components/Admin/components/AdminKarir";
import AdminSertifikasi from "./Components/Admin/components/AdminSertifikasi";
import AdminSustainability from "./Components/Admin/components/AdminSustainability";
import AdminSidebar from "./Components/Admin/components/AdminSidebar";
import Login from "./Components/Admin/components/Login";
import Register from "./Components/Admin/components/Register";
import AdminBisnis from "./Components/Admin/components/AdminBisnis";

function App() {
  const location = useLocation();
  // Update logic to check if the current path is an admin path
  const isAdminPage = location.pathname.startsWith("/jayarubbermanagearea");
  const isLoginPage = location.pathname === "/jayarubbersignin";
  const isRegisterPage = location.pathname === "/jayarubbersignup";

  const [selectedArtikel, setSelectedArtikel] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token); // Set isLoggedIn menjadi true jika token ada
  }, []);

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
    navigate("/jayarubbermanagearea"); // Redirect ke halaman utama setelah login
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("token"); // Hapus JWT dari localStorage
    navigate("/"); // Redirect ke halaman login setelah logout
  };

  const ProtectedRoute = ({ element }) => {
    const token = localStorage.getItem("token");
    return token ? element : <Navigate to="/jayarubbersignin" />;
  };

  return (
    <div className="App">
      <ScrollOnTop />
      {isAdminPage ? (
        <div className="flex h-screen">
          {isLoggedIn && <AdminSidebar onLogout={handleLogout} />}

          <div className="flex-1 flex flex-col">
            <div className="flex-1 overflow-auto">
              <Routes>
                <Route
                  path="/jayarubbermanagearea"
                  element={<ProtectedRoute element={<AdminMainContent />} />}
                />
                <Route
                  path="/jayarubbermanagearea/artikelrjl"
                  element={<ProtectedRoute element={<AdminArtikel />} />}
                />
                <Route
                  path="/jayarubbermanagearea/sustainabilityrjl"
                  element={<ProtectedRoute element={<AdminSustainability />} />}
                />
                <Route
                  path="/jayarubbermanagearea/sertifikasirjl"
                  element={<ProtectedRoute element={<AdminSertifikasi />} />}
                />
                <Route
                  path="/jayarubbermanagearea/karirrjl"
                  element={<ProtectedRoute element={<AdminKarir />} />}
                />
                <Route
                  path="/jayarubbermanagearea/bisnisrjl"
                  element={<ProtectedRoute element={<AdminBisnis />} />}
                />
              </Routes>
            </div>
          </div>
        </div>
      ) : (
        <>
          {!isRegisterPage && !isLoginPage && !isAdminPage && <Navbar />}
          <Routes>
            <Route
              path="/"
              element={<Beranda setSelectedArtikel={setSelectedArtikel} />}
            />
            <Route
              path="/artikel"
              element={<Artikel setSelectedArtikel={setSelectedArtikel} />}
            />
            <Route
              path="/artikel/:id"
              element={<ArtikelDetail artikelDetail={selectedArtikel} />}
            />
            <Route path="/bisnis" element={<Bisnis />} />
            <Route path="/hubungikami" element={<HubungiKami />} />
            <Route path="/sertifikasi" element={<Sertifikasi />} />
            <Route path="/sustainability" element={<Sustainability />} />
            <Route path="/tentangkami" element={<TentangKami />} />
            <Route path="/karir" element={<Karir />} />
            <Route
              path="/jayarubbersignin"
              element={<Login onLoginSuccess={handleLoginSuccess} />}
            />
            <Route path="/jayarubbersignup" element={<Register />} />
          </Routes>
          {!isRegisterPage && !isLoginPage && !isAdminPage && <Footer />}
        </>
      )}
    </div>
  );
}

export default App;
