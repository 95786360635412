import React, { useEffect, useState, useRef } from "react";
import { FaArrowRight } from "react-icons/fa6";
import section2Img from "../Images/section2Img.jpeg";
import section3Img from "../Images/section3Img.jpg";
import depanRJL from "../Images/depanRJL.jpg";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import useSectionVisibility from "../Function/SectionVisible";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CreateIcon from "@mui/icons-material/Create";
import axios from "axios";

const Beranda = ({ setSelectedArtikel }) => {
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          "https://api.rubberjayalampung.com/artikel"
        );
        const datas = response.data;
        const sortedData = datas.payload.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        ); // Urutkan data berdasarkan tanggal terbaru
        const latestFourData = sortedData.slice(0, 4); // Ambil 4 data terbaru

        setAllData(latestFourData);
        console.log("data", allData);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Bulan dimulai dari 0
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };
  const handleClick = (data) => {
    setSelectedArtikel(data);
    navigate(`/artikel/${data.id}`);
  };

  const { sectionVisible, section1Ref, section2Ref, section3Ref } =
    useSectionVisibility();

  return (
    <div>
      <section
        ref={section1Ref}
        className="h-screen bg-cover bg-center"
        style={{
          backgroundImage: `url(${depanRJL})`,
        }}
      >
        <div className="h-full bg-black bg-opacity-50 flex flex-col justify-between">
          <div className="flex flex-col gap-6  justify-center h-full px-10 md:pt-10 pt-10">
            <h1 className="text-white text-2xl md:text-4xl font-bold md:w-1/2">
              {t(`home.section1Title`)}
            </h1>

            <Link to="/tentangkami">
              <button className="py-2 px-4 rounded-md bg-red-600 text-white flex gap-2 items-center">
                {t(`home.button`)}
                <FaArrowRight />
              </button>
            </Link>
          </div>
          <div className="bg-gray-700 py-4 px-10  bg-opacity-70 hover:bg-opacity-100 hover:ease-in-out hover:duration-300 hidden md:flex flex-col md:flex-row items-center mt-[-50px] md:h-36 h-56">
            <h1 className="text-white text-3xl font-medium md:w-1/4">
              {t(`home.newestArticles`)}
            </h1>
            <div className="w-full md:w-3/4">
              <Carousel
                showArrows={true}
                showThumbs={false}
                showStatus={false}
                showIndicators={false}
                infiniteLoop={true}
                autoPlay={true}
                swipeable={false}
              >
                {allData.map((data, index) => (
                  <div
                    onClick={() => handleClick(data)}
                    key={index}
                    className="text-white py-4 px-10 md:px-16 cursor-pointer"
                  >
                    <div className="pb-2 flex items-start justify-center md:justify-start">
                      <h2 className="text-xl font-semibold">
                        {truncateText(data.judul, 50)}
                      </h2>
                    </div>
                    <p className="text-sm pb-2 flex items-start justify-center md:justify-start">
                      {truncateText(data.deskripsi, 100)}
                    </p>
                    <hr className="w-full bg-white h-px" />
                    <div className="flex items-center gap-4  md:justify-start py-2">
                      <div className="flex items-center">
                        <span className="mr-2">
                          <CalendarMonthIcon fontSize="small" />
                        </span>
                        <p className="text-xs text-gray-400 mb-0">
                          {formatDate(data.created_at)}
                        </p>
                      </div>
                      <div className="flex items-center">
                        <span className="mr-2">
                          <CreateIcon fontSize="small" />
                        </span>
                        <p className="text-xs text-gray-400">
                          {t(`home.writtenBy`)}{" "}
                          <span className="font-semibold">
                            {` ${data.penulis}`}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </section>

      <section
        ref={section2Ref}
        className="h-screen  bg-cover bg-center"
        style={{
          backgroundImage: `url(${section2Img})`,
        }}
      >
        <div className="h-full bg-black bg-opacity-50 flex items-center px-10 ">
          <div
            className={`ease-in-out duration-700 ${
              sectionVisible.section2
                ? "translate-y-0 opacity-100"
                : "translate-y-20 opacity-0"
            }`}
          >
            <h1 className="text-white text-4xl font-bold md:w-1/2">
              {t(`home.section2Title`)}
            </h1>
            <p className="text-white md:w-1/2 pt-6 pb-6">
              {t(`home.section2Desc`)}
            </p>
            <Link to="/sustainability">
              <button className="py-2 px-4 rounded-md bg-red-600 text-white flex gap-2 items-center">
                {t(`home.button`)} <FaArrowRight />
              </button>
            </Link>
          </div>
        </div>
      </section>

      <section
        ref={section3Ref}
        className="h-screen bg-cover bg-center"
        style={{
          backgroundImage: `url(${section3Img})`,
        }}
      >
        <div className="h-full bg-black bg-opacity-50 flex items-center px-10 ">
          <div
            className={`ease-in-out duration-700 ${
              sectionVisible.section3
                ? "translate-y-0 opacity-100"
                : "translate-y-20 opacity-0"
            }`}
          >
            <h1 className="text-white text-4xl font-bold md:w-1/2">
              {t(`home.section3Title`)}
            </h1>
            <p className="text-white md:w-1/2 pt-6 pb-6">
              {t(`home.section3Desc`)}
            </p>
            <Link to="/bisnis">
              <button className="py-2 px-4 rounded-md bg-red-600 text-white flex gap-2 items-center">
                {t(`home.button`)} <FaArrowRight />
              </button>
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Beranda;
