import React from "react";
import logoRJL from "../assets/image/logoRJL.png";
import { Link } from "react-router-dom";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import FactCheckRoundedIcon from "@mui/icons-material/FactCheckRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import WorkIcon from "@mui/icons-material/Work";
import RecyclingIcon from "@mui/icons-material/Recycling";
import BusinessIcon from "@mui/icons-material/Business";

const AdminSidebar = ({ onLogout }) => {
  return (
    <div className="">
      <div className="h-screen bg-gradient-to-b from-blue-400  to-blue-500 shadow-lg w-64">
        <div className="flex items-center justify-center px-6 py-4 gap-2 ">
          <img src={logoRJL} alt="" className="h-16 w-16" />
          <h3 className="text-white font-bold">PT. RUBBER JAYA LAMPUNG</h3>
        </div>

        <ul className="  text-white">
          <Link to="/jayarubbermanagearea">
            <li className="px-6 py-4 hover:bg-black hover:bg-opacity-40  font-semibold text-sm items-center cursor-pointer flex gap-2 group">
              <HomeRoundedIcon className="group-hover:animate-shakeRotate" />
              BERANDA
            </li>
          </Link>
          <Link to="/jayarubbermanagearea/artikelrjl">
            <li className="px-6 py-4 hover:bg-black hover:bg-opacity-40 font-semibold text-sm items-center cursor-pointer flex gap-2 group">
              <MenuBookRoundedIcon className="group-hover:animate-shakeRotate" />
              ARTIKEL
            </li>
          </Link>
          <Link to="/jayarubbermanagearea/sertifikasirjl">
            <li className="px-6 py-4 hover:bg-black hover:bg-opacity-40 font-semibold text-sm items-center cursor-pointer flex gap-2 group">
              <FactCheckRoundedIcon className="group-hover:animate-shakeRotate" />
              SERTIFIKASI
            </li>
          </Link>
          <Link to="/jayarubbermanagearea/sustainabilityrjl">
            <li className="px-6 py-4 hover:bg-black hover:bg-opacity-40 font-semibold text-sm items-center cursor-pointer flex gap-2 group">
              <RecyclingIcon className="group-hover:animate-shakeRotate" />
              SUSTAINABILITY
            </li>
          </Link>
          <Link to="/jayarubbermanagearea/karirrjl">
            <li className="px-6 py-4 hover:bg-black hover:bg-opacity-40 font-semibold text-sm items-center cursor-pointer flex gap-2 group">
              <WorkIcon className="group-hover:animate-shakeRotate" />
              KARIR
            </li>
          </Link>
          <Link to="/jayarubbermanagearea/bisnisrjl">
            <li className="px-6 py-4 hover:bg-black hover:bg-opacity-40 font-semibold text-sm items-center cursor-pointer flex gap-2 group">
              <BusinessIcon className="group-hover:animate-shakeRotate" />
              BISNIS
            </li>
          </Link>

          <li
            onClick={onLogout}
            className="px-6 py-4 hover:bg-black hover:bg-opacity-40 font-semibold text-sm items-center cursor-pointer flex gap-2 group"
          >
            <LogoutRoundedIcon className="group-hover:animate-shakeRotate" />
            LOGOUT
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AdminSidebar;
