import React from "react";
import { FaHelmetSafety } from "react-icons/fa6";
import { MdHealthAndSafety } from "react-icons/md";
import { PiPlantFill } from "react-icons/pi";
import { RiEmotionHappyFill } from "react-icons/ri";
import useSectionVisibility from "../Function/SectionVisible";
import Carousel from "../Carousel/Carousel";
import tentangKami3 from "../Images/IMG-20240912-WA0001_1.jpg";
import tentangKami4 from "../Images/tentangKami4.jpeg";
import tentangKamiASRI from "../Images/tentangKamiASRI.jpeg";
import { useTranslation } from "react-i18next";

const TentangKami = () => {
  const { t } = useTranslation();

  const { sectionVisible, section1Ref, section2Ref, section3Ref, section4Ref } =
    useSectionVisibility();

  return (
    <div>
      <section
        ref={section1Ref}
        className="h-screen md:w-full w-screen bg-cover bg-center"
      >
        <Carousel />
      </section>

      <section
        ref={section2Ref}
        className="h-screen bg-cover bg-center "
        style={{
          backgroundImage: `url(${tentangKamiASRI})`,
        }}
      >
        <div className="h-full  flex flex-row items-center bg-black bg-opacity-60 justify-center w-full px-10 gap-4">
          <div
            className={`ease-in-out duration-700 ${
              sectionVisible.section2
                ? "translate-y-0 opacity-100"
                : "translate-y-20 opacity-0"
            }`}
          >
            <div className="flex flex-col gap-16 ">
              <h1 className="text-white md:text-4xl text-2xl font-bold text-center ">
                {t(`aboutUs.section2Title`)}
              </h1>

              <div className="flex gap-6 flex-wrap justify-center border-b-2 border-white pb-8">
                <div className="bg-gray-600 h-28 w-28 md:h-60 md:w-60 p-8 transform transition duration-200 hover:scale-110 bg-opacity-70 hover:bg-opacity-100 hover:bg-red-700 rounded-lg flex flex-col gap-2 justify-center items-center font-bold text-white">
                  <div>
                    <FaHelmetSafety
                      className="md:text-[128px] text-[64px]"
                      color="white"
                    />
                  </div>
                  <h3 className="text-xs text-center">
                    {" "}
                    {t(`aboutUs.section2Value1`)}
                  </h3>
                </div>
                <div className="bg-gray-600 h-28 w-28 md:h-60 md:w-60 p-8 transform transition duration-200 hover:scale-110 bg-opacity-70 hover:bg-opacity-100 hover:bg-red-700 rounded-lg flex flex-col gap-2 justify-center items-center font-bold text-white">
                  <div>
                    <MdHealthAndSafety
                      className="md:text-[128px] text-[64px]"
                      color="white"
                    />
                  </div>
                  <h3 className="text-xs text-center">
                    {" "}
                    {t(`aboutUs.section2Value2`)}
                  </h3>
                </div>
                <div className="bg-gray-600 h-28 w-28 md:h-60 md:w-60 p-8 transform transition duration-200 hover:scale-110 bg-opacity-70 hover:bg-opacity-100 hover:bg-red-700 rounded-lg flex flex-col gap-2 justify-center items-center font-bold text-white">
                  <div>
                    <PiPlantFill
                      className="md:text-[128px] text-[64px]"
                      color="white"
                    />
                  </div>
                  <h3 className="text-xs text-center">
                    {" "}
                    {t(`aboutUs.section2Value3`)}
                  </h3>
                </div>
                <div className="bg-gray-600 h-28 w-28 md:h-60 md:w-60 p-8 transform transition duration-200 hover:scale-110 bg-opacity-70 hover:bg-opacity-100 hover:bg-red-700 rounded-lg flex flex-col gap-2 justify-center items-center font-bold text-white">
                  <div>
                    <RiEmotionHappyFill
                      className="md:text-[128px] text-[64px]"
                      color="white"
                    />
                  </div>
                  <h3 className="text-xs text-center">
                    {" "}
                    {t(`aboutUs.section2Value4`)}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        ref={section3Ref}
        className="h-screen bg-cover bg-center"
        style={{
          backgroundImage: `url(${tentangKami3})`,
        }}
      >
        <div
          className={`h-full bg-black bg-opacity-50 flex md:flex-row flex-col items-center justify-center w-full px-10 py-10 md:gap-4 gap-8`}
        >
          <div
            className={` ease-in-out duration-700  ${
              sectionVisible.section3
                ? "translate-y-0 opacity-100"
                : "translate-y-20 opacity-0"
            }`}
          >
            <div className="flex flex-col md:gap-8 gap-4">
              <h1 className="text-white md:text-4xl text-2xl font-bold text-center ">
                {t(`aboutUs.section3Title`)}
              </h1>

              <p className="text-white italic md:text-2xl text-base font-medium text-center px-12 bg-gray-600 bg-opacity-70 md:py-8 py-4 rounded-lg">
                {t(`aboutUs.section3Desc`)}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        ref={section4Ref}
        className="h-screen bg-cover bg-center"
        style={{
          backgroundImage: `url(${tentangKami4})`,
        }}
      >
        <div
          className={`h-full bg-black bg-opacity-70 flex md:flex-row flex-col items-center justify-center w-full px-10 py-10 md:gap-4 gap-8 `}
        >
          <div
            className={`ease-in-out duration-700  ${
              sectionVisible.section4
                ? "translate-y-0 opacity-100"
                : "translate-y-20 opacity-0"
            }`}
          >
            <div className="flex flex-col md:gap-8 gap-4">
              <h1 className="text-white md:text-4xl text-2xl font-bold text-center ">
                {t(`aboutUs.section4Title`)}
              </h1>

              <div className="text-white italic md:text-base text-xs md:text-center text-start  flex flex-wrap justify-center w-full md:gap-6 gap-2">
                <p className="bg-gray-600 md:w-80 md:h-44 p-8 transform transition duration-200 hover:scale-110 bg-opacity-70 hover:bg-opacity-100 hover:bg-red-700 rounded-lg flex flex-col gap-2 justify-center items-center font-bold text-white">
                  {t(`aboutUs.section4Desc1`)}
                </p>
                <p className="bg-gray-600 md:w-80 md:h-44 p-8 transform transition duration-200 hover:scale-110 bg-opacity-70 hover:bg-opacity-100 hover:bg-red-700 rounded-lg flex flex-col gap-2 justify-center items-center font-bold text-white">
                  {t(`aboutUs.section4Desc2`)}
                </p>
                <p className="bg-gray-600 md:w-80 md:h-44 p-8 transform transition duration-200 hover:scale-110 bg-opacity-70 hover:bg-opacity-100 hover:bg-red-700 rounded-lg flex flex-col gap-2 justify-center items-center font-bold text-white">
                  {t(`aboutUs.section4Desc3`)}
                </p>
                <p className="bg-gray-600 md:w-80 md:h-44 p-8 transform transition duration-200 hover:scale-110 bg-opacity-70 hover:bg-opacity-100 hover:bg-red-700 rounded-lg flex flex-col gap-2 justify-center items-center font-bold text-white">
                  {t(`aboutUs.section4Desc4`)}
                </p>
                <p className="bg-gray-600 md:w-80 md:h-44 p-8 transform transition duration-200 hover:scale-110 bg-opacity-70 hover:bg-opacity-100 hover:bg-red-700 rounded-lg flex flex-col gap-2 justify-center items-center font-bold text-white">
                  {t(`aboutUs.section4Desc5`)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TentangKami;
