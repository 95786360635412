import React, { useState, useEffect } from "react";
import tentangKami1 from "../Images/tentangKami1.jpg";
import tentangKami2 from "../Images/tentangKami2.jpeg";
import { useTranslation } from "react-i18next";

const Carousel = () => {
  const { t } = useTranslation();

  const images = [
    {
      img: tentangKami1,
      title: t(`aboutUs.section1Title`),
      desc: t(`aboutUs.section1Desc1`),
    },
    {
      img: tentangKami2,
      title: t(`aboutUs.section1Title`),
      desc: t(`aboutUs.section1Desc2`),
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [images.length]);

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className="relative h-full z-10">
      <div className="relative h-full overflow-hidden">
        {images.map((image, index) => (
          <div
            key={index}
            className={`absolute block h-full w-screen top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition-opacity duration-700 ease-in-out ${
              index === currentSlide ? "opacity-100" : "opacity-0"
            }`}
            style={{ transitionDuration: "700ms" }}
          >
            <img
              src={image.img}
              alt={`Slide ${index}`}
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-black bg-opacity-60"></div>
            <div className="absolute inset-0 flex flex-col justify-center items-center md:items-start md:text-start text-center text-white px-4 md:px-10 py-12">
              <div className="md:w-1/2 w-full">
                <h2 className="md:text-4xl font-bold text-2xl ">
                  {image.title}
                </h2>
                <p className="md:text-base text-xs pt-2">{image.desc}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="absolute z-30 flex -translate-x-1/2 bottom-5 left-1/2 space-x-3">
        {images.map((_, index) => (
          <button
            key={index}
            type="button"
            className={`w-3 h-3 rounded-full ${
              index === currentSlide ? "bg-red-800" : "bg-gray-300"
            }`}
            aria-current={index === currentSlide}
            aria-label={`Slide ${index + 1}`}
            onClick={() => goToSlide(index)}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
