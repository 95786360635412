import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../Function/i18n";
import { FiAlignJustify, FiX } from "react-icons/fi";
import logoRJL from "../Images/logoRJL.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [navbarScroll, setNavbarScroll] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbarScroll(true);
    } else {
      setNavbarScroll(false);
    }
  };

  const handleMenuClick = () => {
    setMenuOpen(false);
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <div className="">
      <div
        className={`ease-in-out duration-500 z-20 w-screen md:w-full fixed top-0 bg-gray-700 md:px-10 px-4 pb-1 pt-1 text-sm text-white 
          ${
            navbarScroll || menuOpen
              ? ""
              : `ease-in-out duration-300 bg-opacity-70`
          }`}
      >
        <div className="flex gap-6 ">
          <div className="flex items-center justify-between w-full">
            <div className="flex gap-5 items-center cursor-pointer w-full">
              <Link to="/">
                <img src={logoRJL} className="md:w-16 md:h-16 w-12 h-12" />
              </Link>
              <p className="md:font-medium md:text-lg text-sm">
                PT RUBBER JAYA LAMPUNG
              </p>
            </div>
            <div className="xl:hidden flex items-center">
              <button
                onClick={() => setMenuOpen(!menuOpen)}
                className="text-2xl text-white focus:outline-none"
              >
                {menuOpen ? (
                  <FiX style={{ transition: "transform 0.3s ease" }} />
                ) : (
                  <FiAlignJustify
                    style={{ transition: "transform 0.3s ease" }}
                  />
                )}
              </button>
            </div>
          </div>

          <div className="basis-3/4 xl:flex xl:gap-6 gap-2 hidden font-light text-nowrap text-sm w-full text-white items-center">
            <div className="hover:text-red-600 cursor-pointer">
              <Link to="/">{t(`navbar.home`)}</Link>
            </div>
            <div className="hover:text-red-600 cursor-pointer">
              <Link to="/tentangkami">{t(`navbar.aboutUs`)}</Link>
            </div>
            <div className="hover:text-red-600 cursor-pointer">
              <Link to="/bisnis">{t(`navbar.business`)}</Link>
            </div>
            <div className="hover:text-red-600 cursor-pointer">
              <Link to="/sertifikasi">{t(`navbar.certification`)}</Link>
            </div>
            <div className="hover:text-red-600 cursor-pointer">
              <Link to="/sustainability">{t(`navbar.sustainability`)}</Link>
            </div>
            <div className="hover:text-red-600 cursor-pointer">
              <Link to="/artikel">{t(`navbar.articles`)}</Link>
            </div>
            <div className="hover:text-red-600 cursor-pointer">
              <Link to="/karir">{t(`navbar.career`)}</Link>
            </div>
            <div className="hover:text-red-600 cursor-pointer">
              <Link to="/hubungikami">{t(`navbar.contactUs`)}</Link>
            </div>

            <div className=" flex gap-3">
              <h2
                onClick={() => changeLanguage("id")}
                className="hover:text-red-600 cursor-pointer"
              >
                ID
              </h2>
              <span>|</span>
              <h2
                onClick={() => changeLanguage("en")}
                className="hover:text-red-600 cursor-pointer"
              >
                EN
              </h2>
            </div>
          </div>
        </div>

        {menuOpen && (
          <div className="xl:hidden flex flex-col gap-8 mt-8 pb-4">
            <div className="hover:text-red-600 cursor-pointer">
              <Link to="/" onClick={handleMenuClick}>
                {t(`navbar.home`)}
              </Link>
            </div>
            <div className="hover:text-red-600 cursor-pointer">
              <Link to="/tentangkami" onClick={handleMenuClick}>
                {t(`navbar.aboutUs`)}
              </Link>
            </div>
            <div className="hover:text-red-600 cursor-pointer">
              <Link to="/bisnis" onClick={handleMenuClick}>
                {t(`navbar.business`)}
              </Link>
            </div>
            <div className="hover:text-red-600 cursor-pointer">
              <Link to="/sertifikasi" onClick={handleMenuClick}>
                {t(`navbar.certification`)}
              </Link>
            </div>
            <div className="hover:text-red-600 cursor-pointer">
              <Link to="/sustainability" onClick={handleMenuClick}>
                {t(`navbar.sustainability`)}
              </Link>
            </div>
            <div className="hover:text-red-600 cursor-pointer">
              <Link to="/artikel" onClick={handleMenuClick}>
                {t(`navbar.articles`)}
              </Link>
            </div>
            <div className="hover:text-red-600 cursor-pointer">
              <Link to="/karir" onClick={handleMenuClick}>
                {t(`navbar.career`)}
              </Link>
            </div>
            <div className="hover:text-red-600 cursor-pointer">
              <Link to="/hubungikami" onClick={handleMenuClick}>
                {t(`navbar.contactUs`)}
              </Link>
            </div>
            <div className=" flex gap-3">
              <h2
                onClick={() => changeLanguage("id")}
                className="hover:text-red-600 cursor-pointer"
              >
                ID
              </h2>
              <span>|</span>
              <h2
                onClick={() => changeLanguage("en")}
                className="hover:text-red-600 cursor-pointer"
              >
                EN
              </h2>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
