import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const Login = ({ onLoginSuccess }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false); // Add state for remember me
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate(); // Hook untuk navigasi

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(""); // Reset error message
    try {
      const response = await axios.post(
        "https://api.rubberjayalampung.com/jayarubbersignin",
        {
          username,
          password,
        }
      );
      const token = response.data.token;
      if (response.status === 200) {
        localStorage.setItem("token", token);
        // Navigasi ke halaman utama setelah login sukses
        onLoginSuccess(); // Panggil callback login sukses
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setErrorMessage(error.response.data.message); // Set error message
      } else {
        setErrorMessage("There was an error logging in!"); // Handle other errors
      }
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-blue-300 w-full">
      <div className="w-full max-w-md bg-white rounded-lg shadow-md p-8">
        <h2 className="text-2xl font-bold text-center mb-6">Login</h2>
        {errorMessage && <p className="text-red-500">{errorMessage}</p>}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              className="block text-gray-700 font-bold mb-2"
              htmlFor="username"
            >
              Username
            </label>
            <input
              type="text"
              id="username"
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Masukkan username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="mb-6">
            <label
              className="block text-gray-700 font-bold mb-2"
              htmlFor="password"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Masukkan password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {/* <div className="flex items-center mb-6 w-full">
            <button
              type="button"
              className="text-blue-500 hover:underline"
              onClick={() => console.log("Forgot Password clicked")}
            >
              Forgot Password?
            </button>
          </div> */}
          <button
            type="submit"
            className="w-full bg-blue-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 mb-6"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
