import { useState, useRef, useLayoutEffect } from "react";

const useSectionVisibility = () => {
  const [sectionVisible, setSectionVisible] = useState({
    section2: false,
    section3: false,
    section4: false,
  });

  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);

  const handleScroll = () => {
    const scrollPosition = window.scrollY + window.innerHeight;
    if (section2Ref.current) {
      const section2OffsetTop = section2Ref.current.offsetTop;

      if (scrollPosition >= section2OffsetTop + 400) {
        setSectionVisible((prev) => ({ ...prev, section2: true }));
      } else {
        setSectionVisible((prev) => ({ ...prev, section2: false }));
      }
    }

    if (section3Ref.current) {
      const section3OffsetTop = section3Ref.current.offsetTop;
      if (scrollPosition >= section3OffsetTop + 400) {
        setSectionVisible((prev) => ({ ...prev, section3: true }));
      } else {
        setSectionVisible((prev) => ({ ...prev, section3: false }));
      }
    }
    if (section4Ref.current) {
      const section4OffsetTop = section4Ref.current.offsetTop;
      if (scrollPosition >= section4OffsetTop + 400) {
        setSectionVisible((prev) => ({ ...prev, section4: true }));
      } else {
        setSectionVisible((prev) => ({ ...prev, section4: false }));
      }
    }
  };

  useLayoutEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return { sectionVisible, section1Ref, section2Ref, section3Ref, section4Ref };
};

export default useSectionVisibility;
